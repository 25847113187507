import { put, select } from 'redux-saga/effects';
import { setError } from '../../redux/actions';
import { selectRoutes } from '../../redux/selectors/routeSelector';

export function* errorHandler(error, displayError = true) {
    if (error.response?.status === 401) {
        // Unauthorized, redirect to login
        const routes = yield select(selectRoutes);
        window.location.href =
            process.env.REACT_APP_LOGIN_URL +
            '/logout?redirect=' +
            encodeURIComponent(routes.history.location.pathname);
    }

    if (!displayError) {
        return;
    }

    console.error(error);
    yield put(
        setError({
            status: error.response && error.response.status,
            message: error.response?.data?.message || error.message,
        })
    );
}
