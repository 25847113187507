import { createSelector } from 'reselect';
import {
    dsoEnum,
    operationFunctionalities,
    participantsFilterTypes,
} from '../../../../app-config';
import {
    getComparator,
    tableSort,
} from '../../../adapters/primary/libs/sortTable';
import { selectSelectedDates } from './measurementSelectors/sharedSelectors';
import { dateRangeOverlaps } from '../../../adapters/primary/libs/datesLib';
import { accountStatus } from '../../../adapters/primary/ui/enoapp/components/Participants/ViewListParticipants/accountStatus';
import infoStatus, {
    InfoStatusOrder,
} from '../../../adapters/primary/ui/enoapp/components/Participants/ViewListParticipants/infoStatus';

const operation = (state) => state.operation;

const selectOperationInterlocutor = (state) => state.operationInterlocutor;

const selectSelectedOperation = createSelector(
    operation,
    (operation) => operation.selectedOperation
);

const selectSelectedOperationId = createSelector(
    selectSelectedOperation,
    (operation) => operation && operation.id
);
const selectSelectedOperationName = createSelector(
    selectSelectedOperation,
    (operation) => operation && operation.name
);
const selectSelectedOperationProducersNumber = createSelector(
    selectSelectedOperation,
    (operation) => operation && operation.producersNumber
);
const selectSelectedOperationIsAMultiProd = createSelector(
    selectSelectedOperation,
    selectSelectedOperationProducersNumber,
    (operation, producersNumber) => operation && producersNumber > 1
);

const selectOperations = createSelector(
    operation,
    (operation) => operation.operations
);

export const selectSortedByEnedisIdOperations = createSelector(
    selectOperations,
    (operations) => {
        const sortedOperations = [...operations];
        sortedOperations.sort((op1, op2) =>
            op1?.enedisId > op2?.enedisId ? 1 : -1
        );
        return sortedOperations;
    }
);

const selectFilter = createSelector(operation, (operation) => operation.filter);

const selectSorting = createSelector(selectFilter, (filter) => filter.sorting);

const selectSearch = createSelector(selectFilter, (filter) => filter.search);

const selectParticipants = createSelector(
    selectSelectedOperation,
    selectSorting,
    selectSearch,
    (selectedOperation, { order, orderBy, type }, search) => {
        let participants = selectedOperation
            ? selectedOperation.consumers
                ? selectedOperation.consumers.concat(
                      selectedOperation.producers
                  )
                : selectedOperation.producers
            : [];
        participants = participants.filter((participant) => {
            const accountStatusFilter = accountStatus(participant)
                .toLowerCase()
                .includes(search.toLowerCase());
            const infoStatusFilter = infoStatus(participant)
                .toLowerCase()
                .includes(search.toLowerCase());
            const nameFilter =
                participant.name &&
                participant.name.toLowerCase().includes(search.toLowerCase());
            const dsoIdFilter =
                participant.dsoId &&
                participant.dsoId.toLowerCase().includes(search.toLowerCase());
            const billingDetailFilter =
                participant.billingDetail?.name &&
                participant.billingDetail.name
                    .toLowerCase()
                    .includes(search.toLowerCase());
            return (
                search === '' ||
                billingDetailFilter ||
                dsoIdFilter ||
                nameFilter ||
                accountStatusFilter ||
                infoStatusFilter
            );
        });

        participants = participants.map((participant) => {
            return {
                ...participant,
                enedisDetail: participant.enedisDetail
                    ? {
                          ...participant.enedisDetail,
                          folder_status:
                              InfoStatusOrder[infoStatus(participant)],
                      }
                    : undefined,
                dsoDetails: participant.dsoDetails
                    ? {
                          ...participant.dsoDetails,
                          folder_status:
                              InfoStatusOrder[infoStatus(participant)],
                      }
                    : undefined,
            };
        });

        if (order !== '' && orderBy !== '') {
            participants = tableSort(
                participants,
                getComparator(order, orderBy, type)
            );
        }
        return participants;
    }
);

const selectType = createSelector(
    selectFilter,
    (filter) => filter.participantType
);

const selectParticipantsAndObservers = createSelector(
    selectSelectedOperation,
    selectType,
    (operation, type) => {
        let participants = [];
        if (operation) {
            const data = {
                consumers: operation[participantsFilterTypes.CONSUMERS].map(
                    (consumer) => {
                        const name =
                            consumer?.name ||
                            consumer?.billingDetail?.name ||
                            consumer?.dsoId ||
                            '';
                        return { ...consumer, name, isConsumer: true };
                    }
                ),
                producers: operation[participantsFilterTypes.PRODUCERS].map(
                    (producer) => {
                        const name =
                            producer?.name ||
                            producer?.billingDetail?.name ||
                            producer?.dsoId ||
                            '';
                        return { ...producer, name, isProducer: true };
                    }
                ),
                observers: operation[participantsFilterTypes.OBSERVERS].map(
                    (observer) => {
                        const name = observer?.name || '';
                        return { ...observer, name, isObserver: true };
                    }
                ),
            };
            participants = operation
                ? type !== 'all'
                    ? data[type]
                    : [...data.consumers, ...data.producers, ...data.observers]
                : [];
            participants.sort((a, b) => {
                return a.name.localeCompare(b.name) >= 0 ? 1 : -1;
            });
        }
        return participants;
    }
);

const selectFunctionalities = createSelector(
    selectSelectedOperation,
    (operation) => operation && operation.functionalities
);

const selectIsOperationACI = createSelector(
    selectFunctionalities,
    (functionalities) =>
        functionalities &&
        functionalities.indexOf(operationFunctionalities.ACI) > -1
);

export const selectIsSibelgaOperation = createSelector(
    selectSelectedOperation,
    (operation) => operation?.dso === dsoEnum.SIBELGA
);

const selectShowGesKpi = createSelector(
    selectFunctionalities,
    (functionalities) =>
        functionalities &&
        functionalities.indexOf(operationFunctionalities.GES_KPI) > -1
);

const selectTheme = createSelector(
    selectSelectedOperation,
    (operation) => operation && operation.theme
);

const selectDoesOperationUseEnphaseData = createSelector(
    selectSelectedOperation,
    (operation) => operation && operation.useEnphaseData
);

const selectActiveParticipants = createSelector(
    selectSelectedOperation,
    selectSelectedDates,
    (operation, selectedDates) => {
        return (
            operation && {
                consumers: operation.consumers.filter((consumer) =>
                    dateRangeOverlaps(selectedDates, consumer.activationRange)
                ),
                producers: operation.producers.filter((producer) =>
                    dateRangeOverlaps(selectedDates, producer.activationRange)
                ),
            }
        );
    }
);

export const selectActiveConsumers = createSelector(
    selectActiveParticipants,
    (activeParticipants) => activeParticipants?.consumers || []
);

export const selectActiveProducers = createSelector(
    selectActiveParticipants,
    (activeParticipants) => activeParticipants?.producers || []
);

const selectActiveProsumers = createSelector(
    selectActiveParticipants,
    (participants) =>
        participants
            ? participants.producers
                  .filter((producer) => producer.isProsumer)
                  .map((producer) => producer.id)
            : []
);

export const selectOperationAnniversaryDate = createSelector(
    selectSelectedOperation,
    (operation) => operation?.anniversaryDate
);

export const selectOperationStartDate = createSelector(
    selectOperationAnniversaryDate,
    (anniversaryDate) =>
        anniversaryDate ? anniversaryDate.split('T')[0] : null
);

const makeSelectOperationDso = (operationId) =>
    createSelector(selectOperations, (operations) => {
        const operation = operations.find(
            (operation) => operation.id === operationId
        );
        return operation?.dso;
    });

export const makeIsSibelgaOperation = (operationId) =>
    createSelector(
        makeSelectOperationDso(operationId),
        (dso) => dso === dsoEnum.SIBELGA
    );

export const selectIsThereASibelgaOperation = createSelector(
    selectOperations,
    (operations) =>
        operations.findIndex((operation) => operation.dso === dsoEnum.SIBELGA) >
        -1
);

export {
    selectSelectedOperation,
    selectSelectedOperationId,
    selectOperations,
    selectParticipants,
    selectSelectedOperationName,
    selectSorting,
    selectParticipantsAndObservers,
    selectType,
    selectIsOperationACI,
    selectShowGesKpi,
    selectTheme,
    selectDoesOperationUseEnphaseData,
    selectActiveParticipants,
    selectActiveProsumers,
    selectSelectedOperationIsAMultiProd,
    selectOperationInterlocutor,
};
