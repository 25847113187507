import { createSelector } from 'reselect';
import { selectSelectedOperation } from './operationSelectors';
const selectBillingEntities = (state) => state.billingNew.billingEntities;
const selectBillableEntities = (state) => state.billingNew.billableEntities;
const selectInvoices = (state) => state.billingNew.invoices;
const selectLastInvoiceDate = (state) => state.billingNew.lastInvoiceDate;
const selectBillingPlans = (state) => state.billingNew.billingPlans || [];
const selectIuBillingEntityLogo = (state) => state.billingNew.billingEntityLogo;
const selectBillingSubscriptions = (state) => {
    return state.billingNew.billingSubscriptions || [];
};

export const makeSelectConsumerSubscriptions = (consumerId) =>
    createSelector(selectBillingSubscriptions, (billingSubscriptions) =>
        billingSubscriptions.filter(
            (subscription) => subscription.consumerId === consumerId
        )
    );

export const makeSelectProducerSubscriptions = (producerId) =>
    createSelector(selectBillingSubscriptions, (billingSubscriptions) =>
        billingSubscriptions.filter(
            (subscription) => subscription.producerId === producerId
        )
    );

const selectIuBillingEntity = createSelector(
    selectBillingEntities,
    (billingEntities) =>
        billingEntities?.find((entity) => entity.producerIds.length === 0)
);
const selectIuBillableEntity = createSelector(
    selectBillableEntities,
    (billableEntities) =>
        billableEntities?.find((entity) => entity.consumerIds.length === 0)
);

const selectIsOperationNewBillingEnabled = createSelector(
    selectSelectedOperation,
    (operation) => operation?.billingEngine === 'ENGINE_2025'
);

export {
    selectBillingEntities,
    selectBillableEntities,
    selectInvoices,
    selectLastInvoiceDate,
    selectBillingPlans,
    selectBillingSubscriptions,
    selectIuBillingEntity,
    selectIuBillableEntity,
    selectIuBillingEntityLogo,
    selectIsOperationNewBillingEnabled,
};
