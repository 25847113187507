import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import { selectSelectedOperation } from '../../redux/selectors/operationSelectors';
import {
    setBillingEntities,
    setBillingPlans,
    setInvoices,
    setLastInvoiceDate,
    setBillingSubscriptions,
    setError,
    setBillableEntities,
    setNotif,
    setBillingEntityLogo,
} from '../../redux/actions';
import {
    CREATE_BILLING_ENTITY,
    CREATE_BILLING_PLAN,
    CREATE_MULTIPLE_SUBSCRIPTIONS,
    CREATE_BILLING_SUBSCRIPTION,
    GET_BILLING_ENTITIES,
    GET_BILLING_PLANS,
    GET_BILLING_SUBSCRIPTIONS,
    UPDATE_BILLING_ENTITY,
    GET_INVOICES,
    GET_LAST_INVOICE_DATE,
    DOWNLOAD_INVOICE,
    PUBLISH_INVOICES,
    UPDATE_BILLING_PLAN,
    UPDATE_BILLING_SUBSCRIPTION,
    GET_BILLABLE_ENTITIES,
    UPDATE_BILLABLE_ENTITY,
    CREATE_BILLABLE_ENTITY,
    DELETE_INVOICES,
    GENERATE_INVOICES,
    GET_BILLING_ENTITY_LOGO,
} from '../../redux/reducers/constants';
import i18n from 'i18next';
import {
    selectBillableEntities,
    selectBillingEntities,
} from '../../redux/selectors/billingNewSelector';
import { errorHandler } from '../common/errorHandler';
import {
    selectConsumers,
    selectProducers,
} from '../../redux/selectors/participantSelectors';
import { selectUser } from '../../redux/selectors/authenticationSelectors';

function* getBillingEntities(action) {
    try {
        const user = yield select(selectUser);
        const operation = yield select(selectSelectedOperation);
        const producerId =
            user.role === 'ORGANIZER' ? null : operation.activeProducerId;
        const billingGateway = yield getContext('billingNewGateway');
        let billingEntities = yield call(
            billingGateway.getBillingEntities,
            operation.id
        );
        yield put(
            setBillingEntities(
                billingEntities.filter(
                    (entity) =>
                        !producerId || entity.producerIds.includes(producerId)
                )
            )
        );
    } catch (error) {
        yield errorHandler(error);
    }
}

function* getBillableEntities(action) {
    try {
        const billingGateway = yield getContext('billingNewGateway');
        const operation = yield select(selectSelectedOperation);
        let billableEntities = yield call(
            billingGateway.getBillableEntities,
            operation.id
        );
        yield put(setBillableEntities(billableEntities));
    } catch (error) {
        yield errorHandler(error);
    }
}

function* createBillingEntity(action) {
    try {
        const billingGateway = yield getContext('billingNewGateway');
        const operation = yield select(selectSelectedOperation);
        yield call(
            billingGateway.createBillingEntity,
            operation.id,
            action.entity
        );
        let billingEntities = yield call(
            billingGateway.getBillingEntities,
            operation.id
        );
        yield put(setBillingEntities(billingEntities));
    } catch (error) {
        yield errorHandler(error);
    }
}

function* updateBillingEntity(action) {
    try {
        const billingGateway = yield getContext('billingNewGateway');
        const operation = yield select(selectSelectedOperation);
        const { entity, formData } = action;
        let billingLogoPath;
        if (formData) {
            billingLogoPath = yield call(
                billingGateway.uploadBillingEntityLogo,
                entity,
                operation,
                formData
            );
            if (entity && billingLogoPath) {
                entity.billingLogo = billingLogoPath;
            }
        }
        yield call(billingGateway.updateBillingEntity, operation.id, entity);
        let billingEntities = yield call(
            billingGateway.getBillingEntities,
            operation.id
        );
        yield put(setBillingEntities(billingEntities));
    } catch (error) {
        yield errorHandler(error);
    }
}

function* createBillableEntity(action) {
    try {
        const billingGateway = yield getContext('billingNewGateway');
        const operation = yield select(selectSelectedOperation);
        yield call(
            billingGateway.createBillableEntity,
            operation.id,
            action.entity
        );
        let billableEntities = yield call(
            billingGateway.getBillableEntities,
            operation.id
        );
        yield put(setBillableEntities(billableEntities));
    } catch (error) {
        yield errorHandler(error);
    }
}

function* updateBillableEntity(action) {
    try {
        const billingGateway = yield getContext('billingNewGateway');
        const operation = yield select(selectSelectedOperation);
        yield call(
            billingGateway.updateBillableEntity,
            operation.id,
            action.entity
        );
        let billableEntities = yield call(
            billingGateway.getBillableEntities,
            operation.id
        );
        yield put(setBillableEntities(billableEntities));
    } catch (error) {
        yield errorHandler(error);
    }
}

function* getInvoices(action) {
    try {
        const billingGateway = yield getContext('billingNewGateway');
        const operation = yield select(selectSelectedOperation);
        const user = yield select(selectUser);
        const producerId =
            user.role === 'ORGANIZER' ? null : operation.activeProducerId;
        let invoices = yield call(
            billingGateway.getInvoices,
            operation.id,
            producerId
        );
        yield put(setInvoices(invoices));
    } catch (error) {
        yield errorHandler(error);
    }
}

function* getLastInvoiceDate(action) {
    try {
        const billingGateway = yield getContext('billingNewGateway');
        const operation = yield select(selectSelectedOperation);
        let lastInvoiceDate = yield call(
            billingGateway.getLastInvoiceDate,
            operation.id,
            action.billingEntityId,
            action.billableEntityId
        );
        yield put(setLastInvoiceDate(lastInvoiceDate));
    } catch (error) {
        yield errorHandler(error);
    }
}

function* generateInvoices(action) {
    try {
        const billingGateway = yield getContext('billingNewGateway');
        const { operationId, date } = action;
        yield put(
            setNotif({
                message: i18n.t(
                    'enoapp.administrative.billing.invoices.generateInProgress'
                ),
            })
        );
        const generateResults = yield call(
            billingGateway.generateInvoices,
            operationId,
            date
        );
        let invoices = yield call(billingGateway.getInvoices, operationId);
        yield put(setInvoices(invoices));

        if (generateResults.errors.length === 0) {
            yield put(
                setNotif({
                    message: i18n.t(
                        'enoapp.administrative.billing.invoices.generateSuccess',
                        {
                            count: generateResults.invoices.length,
                        }
                    ),
                })
            );
        } else if (generateResults.invoices.length > 0) {
            yield put(
                setNotif({
                    message: i18n.t(
                        'enoapp.administrative.billing.invoices.generateSuccess',
                        {
                            count: generateResults.invoices.length,
                        }
                    ),
                    variant: 'warning',
                    persist: true,
                })
            );
        }

        if (generateResults.errors.length > 0) {
            const billingEntities = yield select(selectBillingEntities);
            const billableEntities = yield select(selectBillableEntities);
            let errorMessages = [];
            for (let error of generateResults.errors) {
                // I18N error if possible
                const i18nErrorMessage = error.failure.includes(' ')
                    ? error.failure
                    : i18n.t('error.invoices.' + error.failure);

                errorMessages.push(
                    i18n.t(
                        'enoapp.administrative.billing.invoices.generateError',
                        {
                            billingEntity: billingEntities.find(
                                (billingEntity) =>
                                    billingEntity.id === error.billingEntityId
                            )?.name,
                            billableEntity: billableEntities.find(
                                (billableEntity) =>
                                    billableEntity.id === error.billableEntityId
                            )?.name,
                        }
                    ) + i18nErrorMessage
                );
            }
            yield put(
                setError({
                    message: errorMessages.join('\n'),
                    persist: true,
                })
            );
        }
    } catch (error) {
        yield errorHandler(error);
    }
}

function* deleteInvoices(action) {
    try {
        const billingGateway = yield getContext('billingNewGateway');
        const { operationId, invoiceIds } = action;
        yield call(billingGateway.deleteInvoices, operationId, invoiceIds);
        let invoices = yield call(billingGateway.getInvoices, operationId);
        yield put(setInvoices(invoices));
    } catch (error) {
        yield errorHandler(error);
    }
}

function* downloadInvoice(action) {
    try {
        const billingGateway = yield getContext('billingNewGateway');
        const { operationId, invoiceId, language, isLegacyBill } = action;
        const url = yield call(
            billingGateway.getInvoiceDownloadURL,
            operationId,
            invoiceId,
            language,
            isLegacyBill
        );
        window.open(url);
    } catch (error) {
        yield errorHandler(error);
    }
}

function* publishInvoices(action) {
    try {
        const billingGateway = yield getContext('billingNewGateway');
        const { operationId, invoiceIds } = action;
        yield call(billingGateway.publishInvoices, operationId, invoiceIds);
        let invoices = yield call(billingGateway.getInvoices, operationId);
        yield put(setInvoices(invoices));
    } catch (error) {
        yield errorHandler(error);
    }
}

function* getBillingPlans(action) {
    try {
        const billingGateway = yield getContext('billingNewGateway');
        const operation = yield select(selectSelectedOperation);
        let billingPlans = yield call(
            billingGateway.getBillingPlans,
            operation.id
        );
        yield put(setBillingPlans(billingPlans));
    } catch (error) {
        yield errorHandler(error);
    }
}

function* createBillingPlan(action) {
    try {
        const billingGateway = yield getContext('billingNewGateway');
        const operation = yield select(selectSelectedOperation);
        yield call(
            billingGateway.createBillingPlan,
            operation.id,
            action.entity
        );
        let billingPlans = yield call(
            billingGateway.getBillingPlans,
            operation.id
        );
        yield put(setBillingPlans(billingPlans));
    } catch (error) {
        yield errorHandler(error);
    }
}

function* updateBillingPlan(action) {
    try {
        const billingGateway = yield getContext('billingNewGateway');
        const operation = yield select(selectSelectedOperation);
        yield call(
            billingGateway.updateBillingPlan,
            operation.id,
            action.entity
        );
        let billingPlans = yield call(
            billingGateway.getBillingPlans,
            operation.id
        );
        yield put(setBillingPlans(billingPlans));
    } catch (error) {
        yield errorHandler(error);
    }
}

function* getBillingSubscriptions(action) {
    try {
        const billingGateway = yield getContext('billingNewGateway');
        const operation = yield select(selectSelectedOperation);
        let billingSubscriptions = yield call(
            billingGateway.getBillingSubscriptions,
            operation.id
        );
        yield put(setBillingSubscriptions(billingSubscriptions));
    } catch (error) {
        yield errorHandler(error);
    }
}

function* createMultipleSubscriptions(action) {
    try {
        const billingGateway = yield getContext('billingNewGateway');
        const operation = yield select(selectSelectedOperation);
        const results = yield call(
            billingGateway.createMultipleSubscriptions,
            operation.id,
            action.entity
        );
        if (results.errors.length > 0) {
            const consumers = yield select(selectConsumers);
            const producers = yield select(selectProducers);
            let errorMessages = [];
            for (let error of results.errors) {
                const producer = producers.find(
                    (producer) => producer.id === error.producerId
                );
                const consumer = consumers.find(
                    (consumer) => consumer.id === error.consumerId
                );
                if (!producer) {
                    errorMessages.push(
                        i18n.t('error.subscriptions.error_for_consumer', {
                            consumer: consumer?.name,
                        }) + i18n.t('error.subscriptions.' + error.message)
                    );
                } else {
                    errorMessages.push(
                        i18n.t('error.subscriptions.error_for_couple', {
                            producer: producer.name,
                            consumer: consumer?.name,
                        }) + i18n.t('error.subscriptions.' + error.message)
                    );
                }
            }
            yield put(
                setError({
                    message: errorMessages.join('\n'),
                    persist: true,
                })
            );
        }
        let billingSubscriptions = yield call(
            billingGateway.getBillingSubscriptions,
            operation.id
        );
        yield put(setBillingSubscriptions(billingSubscriptions));
    } catch (error) {
        yield errorHandler(error);
    }
}

function* createBillingSubscription(action) {
    try {
        const billingGateway = yield getContext('billingNewGateway');
        const operation = yield select(selectSelectedOperation);
        yield call(
            billingGateway.createBillingSubscription,
            operation.id,
            action.entity
        );
        let billingSubscriptions = yield call(
            billingGateway.getBillingSubscriptions,
            operation.id
        );
        yield put(setBillingSubscriptions(billingSubscriptions));
    } catch (error) {
        yield errorHandler(error);
    }
}

function* updateBillingSubscription(action) {
    try {
        const billingGateway = yield getContext('billingNewGateway');
        const operation = yield select(selectSelectedOperation);
        yield call(
            billingGateway.updateBillingSubscription,
            operation.id,
            action.entity
        );
        let billingSubscriptions = yield call(
            billingGateway.getBillingSubscriptions,
            operation.id
        );
        yield put(setBillingSubscriptions(billingSubscriptions));
    } catch (error) {
        yield errorHandler(error);
    }
}
function* getBillingEntityLogo(action) {
    try {
        const { billingEntity, operation } = action;
        const billingGateway = yield getContext('billingNewGateway');
        const logo = yield call(
            billingGateway.getBillingEntityLogo,
            billingEntity,
            operation
        );
        yield put(setBillingEntityLogo(logo));
    } catch (error) {
        yield put(
            setError({ status: error.response && error.response.status })
        );
        console.error(error);
    }
}

export function* getBillableEntitiesSaga() {
    yield takeLatest(GET_BILLABLE_ENTITIES, getBillableEntities);
}

export function* getBillingEntitiesSaga() {
    yield takeLatest(GET_BILLING_ENTITIES, getBillingEntities);
}

export function* createBillingEntitySaga() {
    yield takeLatest(CREATE_BILLING_ENTITY, createBillingEntity);
}

export function* updateBillingEntitySaga() {
    yield takeLatest(UPDATE_BILLING_ENTITY, updateBillingEntity);
}

export function* createBillableEntitySaga() {
    yield takeLatest(CREATE_BILLABLE_ENTITY, createBillableEntity);
}

export function* updateBillableEntitySaga() {
    yield takeLatest(UPDATE_BILLABLE_ENTITY, updateBillableEntity);
}

export function* getInvoicesSaga() {
    yield takeLatest(GET_INVOICES, getInvoices);
}

export function* getLastInvoiceDateSaga() {
    yield takeLatest(GET_LAST_INVOICE_DATE, getLastInvoiceDate);
}

export function* generateInvoicesSaga() {
    yield takeLatest(GENERATE_INVOICES, generateInvoices);
}

export function* deleteInvoicesSaga() {
    yield takeLatest(DELETE_INVOICES, deleteInvoices);
}

export function* downloadInvoiceSaga() {
    yield takeLatest(DOWNLOAD_INVOICE, downloadInvoice);
}

export function* publishInvoicesSaga() {
    yield takeLatest(PUBLISH_INVOICES, publishInvoices);
}

export function* getBillingPlansSaga() {
    yield takeLatest(GET_BILLING_PLANS, getBillingPlans);
}

export function* createBillingPlanSaga() {
    yield takeLatest(CREATE_BILLING_PLAN, createBillingPlan);
}

export function* updateBillingPlanSaga() {
    yield takeLatest(UPDATE_BILLING_PLAN, updateBillingPlan);
}

export function* getBillingSubscriptionsSaga() {
    yield takeLatest(GET_BILLING_SUBSCRIPTIONS, getBillingSubscriptions);
}

export function* createMultipleSubscriptionsSaga() {
    yield takeLatest(
        CREATE_MULTIPLE_SUBSCRIPTIONS,
        createMultipleSubscriptions
    );
}

export function* createBillingSubscriptionSaga() {
    yield takeLatest(CREATE_BILLING_SUBSCRIPTION, createBillingSubscription);
}

export function* updateBillingSubscriptionSaga() {
    yield takeLatest(UPDATE_BILLING_SUBSCRIPTION, updateBillingSubscription);
}

export function* getBillingEntityLogoSaga() {
    yield takeLatest(GET_BILLING_ENTITY_LOGO, getBillingEntityLogo);
}

const billingSagas = [
    getBillableEntitiesSaga,
    createBillingEntitySaga,
    getBillingEntitiesSaga,
    updateBillingEntitySaga,
    getInvoicesSaga,
    getLastInvoiceDateSaga,
    generateInvoicesSaga,
    deleteInvoicesSaga,
    downloadInvoiceSaga,
    publishInvoicesSaga,
    getBillingPlansSaga,
    createBillingPlanSaga,
    updateBillingPlanSaga,
    getBillingSubscriptionsSaga,
    createMultipleSubscriptionsSaga,
    createBillingSubscriptionSaga,
    updateBillingSubscriptionSaga,
    createBillableEntitySaga,
    updateBillableEntitySaga,
    getBillingEntityLogoSaga,
];

export default billingSagas;
