import {
    all,
    call,
    getContext,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';
import {
    initRange,
    initSelectedDates,
    setCDC,
    setCDCLoading,
    setCO2Metrics,
    setCO2MetricsLoading,
    setDistribution,
    setDistributionLoading,
    setMetrics,
    setMetricsLoading,
    setRange,
    setRangeLoading,
    setSelectedDates,
} from '../../redux/actions';
import {
    GET_AUTO_CONSUMPTION_BY_CONSUMERS_METRICS_DATA,
    GET_AUTO_CONSUMPTION_BY_PRODUCERS_METRICS_DATA,
    GET_CDC_DATA,
    GET_CONSUMER_CDC_DATA,
    GET_CONSUMER_CONSUMPTION_METRICS_DATA,
    GET_CONSUMPTION_BY_CONSUMERS_METRICS_DATA,
    GET_SUPPLIER_CONSUMPTION_BY_PROSUMERS_METRICS_DATA,
    GET_CONSUMPTION_METRICS_DATA,
    GET_DISTRIBUTION_DATA,
    GET_INDIVIDUAL_AUTO_CONSUMPTION_BY_PRODUCERS_METRICS_DATA,
    GET_METRICS_DATA,
    GET_PARTICIPANT_METRICS_DATA,
    GET_PRODUCER_CDC_DATA,
    GET_PRODUCER_PRODUCTION_METRICS_DATA,
    GET_PRODUCTION_BY_PRODUCERS_METRICS_DATA,
    GET_PRODUCTION_METRICS_DATA,
    GET_PROSUMER_CONSUMPTION_METRICS_DATA,
    GET_RANGE,
    GET_SURPLUS_BY_PRODUCERS_METRICS_DATA,
} from '../../redux/reducers/constants';
import {
    dsoEnum,
    MetricNaturesEnum,
    operationFunctionalities,
} from '../../../../app-config';
import {
    selectIsOperationACI,
    selectSelectedOperation,
} from '../../redux/selectors/operationSelectors';
import {
    selectLastVisitedOperationSelectedDates,
    selectSelectedDates,
} from '../../redux/selectors/measurementSelectors/sharedSelectors';
import moment from 'moment';
import { useDefaultPeriod } from '../../../adapters/primary/ui/enoapp/app-config';
import { selectACType } from '../../redux/selectors/functionalitiesSelector';
import { t } from 'i18next';
import { errorHandler } from '../common/errorHandler';

function* getDateRange(action) {
    try {
        yield put(setRangeLoading(true));
        const defaultPeriod = yield call(() => useDefaultPeriod(t));
        const measurementGateway = yield getContext('measurementGateway');
        const [selectedOperation, selectedDates] = yield all([
            select(selectSelectedOperation),
            select(selectLastVisitedOperationSelectedDates),
        ]);
        const participantIds = action.participantId
            ? [action.participantId]
            : null;
        let { uploadedData, sgeTiersData } = yield call(
            measurementGateway.getDateRanges,
            selectedOperation && selectedOperation.id,
            participantIds
        );
        const uploadedDataRange = consolidateRanges(uploadedData);
        const sgeTiersRange = consolidateRanges(sgeTiersData);
        const range = {
            from:
                uploadedDataRange.from && sgeTiersRange.from
                    ? moment.min(uploadedDataRange.from, sgeTiersRange.from)
                    : uploadedDataRange.from || sgeTiersRange.from,
            to:
                uploadedDataRange.to && sgeTiersRange.to
                    ? moment.max(uploadedDataRange.to, sgeTiersRange.to)
                    : uploadedDataRange.to || sgeTiersRange.to,
        };
        yield put(
            setRange({
                uploadedData: uploadedDataRange,
                sgeTiersData: sgeTiersRange,
            })
        );
        if (range.from && range.to) {
            const newRangeTo = range.to.clone();
            const from = moment
                .max(
                    newRangeTo
                        .subtract(defaultPeriod.value, defaultPeriod.unit)
                        .add(1, 'days'),
                    range.from
                )
                .startOf('day')
                .clone();
            let newSelectedDates = { ...selectedDates };
            if (
                !(
                    selectedDates.from &&
                    range.from.isSameOrBefore(selectedDates.from, 'day') &&
                    selectedDates.to &&
                    range.to.isSameOrAfter(selectedDates.to, 'day')
                )
            ) {
                newSelectedDates = {
                    from: from,
                    to: range.to.endOf('day').clone(),
                };
            }
            yield put(setSelectedDates(newSelectedDates));
        } else {
            yield put(initRange());
            yield put(initSelectedDates());
        }
    } catch (error) {
        yield errorHandler(error);
    }
    yield put(setRangeLoading(false));
}

function* getGlobalCDC() {
    try {
        yield put(setCDCLoading(true));
        const measurementGateway = yield getContext('measurementGateway');
        const [{ id: operationId, functionalities }, { from, to }] = yield all([
            select(selectSelectedOperation),
            select(selectSelectedDates),
        ]);
        const isOperationACI =
            functionalities &&
            functionalities.indexOf(operationFunctionalities.ACI) > -1;
        const dataCalls = [];
        dataCalls.push(
            call(measurementGateway.getConsumptionData, operationId, from, to)
        );
        dataCalls.push(
            call(measurementGateway.getProductionData, operationId, from, to)
        );
        dataCalls.push(
            call(
                measurementGateway.getAutoConsumptionData,
                operationId,
                from,
                to
            )
        );
        dataCalls.push(
            call(measurementGateway.getSurplusData, operationId, from, to)
        );
        if (isOperationACI) {
            dataCalls.push(
                call(
                    measurementGateway.getExternalConsumption,
                    operationId,
                    from,
                    to
                )
            );
            dataCalls.push(
                call(
                    measurementGateway.getExternalProduction,
                    operationId,
                    from,
                    to
                )
            );
        }
        const [
            consumption,
            production,
            autoConsumption,
            surplus,
            extConsumption,
            extProduction,
        ] = yield all(dataCalls);
        yield put(
            setCDC({
                consumption,
                production,
                autoConsumption,
                surplus,
                extConsumption,
                extProduction,
            })
        );
    } catch (error) {
        yield errorHandler(error);
    }
    yield put(setCDCLoading(false));
}

function* getCO2Metrics(action) {
    try {
        const operation = yield select(selectSelectedOperation);
        if (operation.dso === dsoEnum.SIBELGA) {
            return;
        }
        yield put(setCO2MetricsLoading(true));
        const measurementGateway = yield getContext('measurementGateway');
        const [{ id: operationId }, { from, to }] = yield all([
            select(selectSelectedOperation),
            select(selectSelectedDates),
        ]);

        const metrics = yield call(
            measurementGateway.getMetrics,
            operationId,
            from,
            to
        );
        yield put(setCO2Metrics(metrics));
    } catch (error) {
        yield errorHandler(error);
    }
    yield put(setCO2MetricsLoading(false));
}
function* getGlobalMetrics(action) {
    const { type } = action;
    let metricNature;
    const [{ id: operationId }, { from, to }, ACType] = yield all([
        select(selectSelectedOperation),
        select(selectSelectedDates),
        select(selectACType),
    ]);
    try {
        const measurementGateway = yield getContext('measurementGateway');

        const acc = ACType === 'ACC';
        const aci = ACType === 'AC collective + individuelle';
        switch (type) {
            case GET_CONSUMPTION_METRICS_DATA:
                metricNature = acc
                    ? MetricNaturesEnum.ACC_CONSUMPTION
                    : aci
                    ? MetricNaturesEnum.ACI_CONSUMPTION
                    : MetricNaturesEnum.ACI_WITH_DETAILS_CONSUMPTION;
                break;
            case GET_PRODUCTION_METRICS_DATA:
                metricNature = acc
                    ? MetricNaturesEnum.ACC_PRODUCTION
                    : aci
                    ? MetricNaturesEnum.ACI_PRODUCTION
                    : MetricNaturesEnum.ACI_WITH_DETAILS_PRODUCTION;
                break;
            default:
                metricNature = '';
        }
        yield put(setMetricsLoading(metricNature, true));

        const metrics = yield call(
            measurementGateway.getGlobalMetrics,
            operationId,
            metricNature,
            from,
            to
        );
        yield put(setMetrics(metricNature, metrics));
    } catch (error) {
        yield errorHandler(error);
    }
    yield put(setMetricsLoading(metricNature, false));
}

function* getParticipantMetrics(action) {
    const { type, participantId } = action;
    const [{ from, to }, ACType] = yield all([
        select(selectSelectedDates),
        select(selectACType),
    ]);
    const acc = ACType === 'ACC';
    let metricNature;
    switch (type) {
        case GET_CONSUMER_CONSUMPTION_METRICS_DATA:
            metricNature = MetricNaturesEnum.CONSUMER_CONSUMPTION;
            break;
        case GET_PRODUCER_PRODUCTION_METRICS_DATA:
            metricNature = acc
                ? MetricNaturesEnum.PRODUCER_ACC_PRODUCTION
                : MetricNaturesEnum.PRODUCER_ACI_PRODUCTION;
            break;
        case GET_PROSUMER_CONSUMPTION_METRICS_DATA:
            metricNature = MetricNaturesEnum.PROSUMER_CONSUMPTION;
            break;
        default:
            metricNature = '';
    }
    try {
        yield put(setMetricsLoading(metricNature, true));
        const measurementGateway = yield getContext('measurementGateway');

        const metrics = yield call(
            measurementGateway.getParticipantMetrics,
            participantId,
            metricNature,
            ACType,
            from,
            to
        );
        yield put(setMetrics(metricNature, metrics));
    } catch (error) {
        yield errorHandler(error);
    }
    yield put(setMetricsLoading(metricNature, false));
}
function* getMetricByParticipantsMetrics(action) {
    let { type, ACType } = action;
    const [{ id: operationId }, { from, to }] = yield all([
        select(selectSelectedOperation),
        select(selectSelectedDates),
    ]);
    if (!ACType) {
        ACType = yield select(selectACType);
    }
    const acc = ACType === 'ACC';
    let metricNature;
    switch (type) {
        case GET_AUTO_CONSUMPTION_BY_CONSUMERS_METRICS_DATA:
            metricNature = MetricNaturesEnum.AUTO_CONSUMPTION_BY_CONSUMERS;
            break;
        case GET_CONSUMPTION_BY_CONSUMERS_METRICS_DATA:
            metricNature = MetricNaturesEnum.CONSUMPTION_BY_CONSUMERS;
            break;
        case GET_SUPPLIER_CONSUMPTION_BY_PROSUMERS_METRICS_DATA:
            metricNature = MetricNaturesEnum.SUPPLIER_CONSUMPTION_BY_PROSUMERS;
            break;
        case GET_PRODUCTION_BY_PRODUCERS_METRICS_DATA:
            metricNature = acc
                ? MetricNaturesEnum.ACC_PRODUCTION_BY_PRODUCERS
                : MetricNaturesEnum.ACI_PRODUCTION_BY_PRODUCERS;
            break;
        case GET_AUTO_CONSUMPTION_BY_PRODUCERS_METRICS_DATA:
            metricNature = acc
                ? MetricNaturesEnum.ACC_AUTO_CONSUMPTION_BY_PRODUCERS
                : MetricNaturesEnum.ACI_AUTO_CONSUMPTION_BY_PRODUCERS;
            break;
        case GET_SURPLUS_BY_PRODUCERS_METRICS_DATA:
            metricNature = MetricNaturesEnum.SURPLUS_BY_PRODUCERS;
            break;
        case GET_INDIVIDUAL_AUTO_CONSUMPTION_BY_PRODUCERS_METRICS_DATA:
            metricNature =
                MetricNaturesEnum.INDIVIDUAL_AUTO_CONSUMPTION_BY_PRODUCERS;
            break;
        default:
            metricNature = '';
    }
    try {
        yield put(setMetricsLoading(metricNature, true));
        const measurementGateway = yield getContext('measurementGateway');

        const metrics = yield call(
            measurementGateway.getGroupByMetrics,
            operationId,
            metricNature,
            ACType,
            from,
            to
        );
        yield put(setMetrics(metricNature, metrics));
    } catch (error) {
        yield errorHandler(error);
    }
    yield put(setMetricsLoading(metricNature, false));
}

function* getConsumerCDC(action) {
    try {
        yield put(setCDCLoading(true));
        const { consumerId } = action;
        const measurementGateway = yield getContext('measurementGateway');
        const { from, to } = yield select(selectSelectedDates);
        const dataCalls = [];
        dataCalls.push(
            call(
                measurementGateway.getConsumerConsumptionData,
                consumerId,
                from,
                to
            )
        );
        dataCalls.push(
            call(
                measurementGateway.getConsumerAutoConsumptionData,
                consumerId,
                from,
                to
            )
        );
        const [consumption, autoConsumption] = yield all(dataCalls);
        yield put(
            setCDC({
                consumption,
                autoConsumption,
            })
        );
    } catch (error) {
        yield errorHandler(error);
    }
    yield put(setCDCLoading(false));
}

function* getParticipantCO2Metrics(action) {
    try {
        const operation = yield select(selectSelectedOperation);
        if (operation.dso === dsoEnum.SIBELGA) {
            return;
        }
        yield put(setCO2MetricsLoading(true));
        const { participantId, isConsumer } = action;
        const measurementGateway = yield getContext('measurementGateway');
        const { from, to } = yield select(selectSelectedDates);
        const metrics = yield call(
            isConsumer
                ? measurementGateway.getConsumerMetrics
                : measurementGateway.getProducerMetrics,
            participantId,
            from,
            to
        );
        yield put(setCO2Metrics(metrics));
    } catch (error) {
        yield errorHandler(error);
    }
    yield put(setCO2MetricsLoading(false));
}

function* getProducerCDC(action) {
    try {
        yield put(setCDCLoading(true));
        const { producerId } = action;
        const measurementGateway = yield getContext('measurementGateway');
        const [isOperationACI, { from, to }] = yield all([
            select(selectIsOperationACI),
            select(selectSelectedDates),
        ]);
        const dataCalls = [];
        dataCalls.push(
            call(
                measurementGateway.getProducerProductionData,
                producerId,
                from,
                to
            )
        );
        dataCalls.push(
            call(
                measurementGateway.getProducerAutoConsumptionData,
                producerId,
                from,
                to
            )
        );
        if (isOperationACI) {
            dataCalls.push(
                call(
                    measurementGateway.getProducerExternalConsumptionData,
                    producerId,
                    from,
                    to
                )
            );
            dataCalls.push(
                call(
                    measurementGateway.getProducerExternalProductionData,
                    producerId,
                    from,
                    to
                )
            );
        }
        const [production, autoConsumption, extConsumption, extProduction] =
            yield all(dataCalls);
        yield put(
            setCDC({
                production,
                autoConsumption,
                extConsumption,
                extProduction,
            })
        );
    } catch (error) {
        yield errorHandler(error);
    }
    yield put(setCDCLoading(false));
}

function* getDistribution() {
    try {
        yield put(setDistributionLoading(true));
        const measurementGateway = yield getContext('measurementGateway');
        const [{ id: operationId }, { from, to }] = yield all([
            select(selectSelectedOperation),
            select(selectSelectedDates),
        ]);

        const distribution = yield call(
            measurementGateway.getDistribution,
            operationId,
            from,
            to
        );

        yield put(setDistribution(distribution));
    } catch (error) {
        yield errorHandler(error);
    }
    yield put(setDistributionLoading(false));
}

export function* getDateRangeSaga() {
    yield takeLatest(GET_RANGE, getDateRange);
}

export function* getCDCSaga() {
    yield takeLatest(GET_CDC_DATA, getGlobalCDC);
}

export function* getMetricsSaga() {
    yield takeLatest(GET_METRICS_DATA, getCO2Metrics);
}

export function* getConsumptionMetricsSaga() {
    yield takeLatest(GET_CONSUMPTION_METRICS_DATA, getGlobalMetrics);
}
export function* getProductionMetricsSaga() {
    yield takeLatest(GET_PRODUCTION_METRICS_DATA, getGlobalMetrics);
}
export function* getConsumerConsumptionMetricsSaga() {
    yield takeLatest(
        GET_CONSUMER_CONSUMPTION_METRICS_DATA,
        getParticipantMetrics
    );
}
export function* getProducerProductionMetricsSaga() {
    yield takeLatest(
        GET_PRODUCER_PRODUCTION_METRICS_DATA,
        getParticipantMetrics
    );
}
export function* getProsumerConsumptionMetricsSaga() {
    yield takeLatest(
        GET_PROSUMER_CONSUMPTION_METRICS_DATA,
        getParticipantMetrics
    );
}
export function* getProductionByProducersMetricsSaga() {
    yield takeLatest(
        GET_PRODUCTION_BY_PRODUCERS_METRICS_DATA,
        getMetricByParticipantsMetrics
    );
}
export function* getAutoConsumptionByProducersMetricsSaga() {
    yield takeLatest(
        GET_AUTO_CONSUMPTION_BY_PRODUCERS_METRICS_DATA,
        getMetricByParticipantsMetrics
    );
}
export function* getSurplusByProducersMetricsSaga() {
    yield takeLatest(
        GET_SURPLUS_BY_PRODUCERS_METRICS_DATA,
        getMetricByParticipantsMetrics
    );
}
export function* getIndividualAutoConsumptionByProducersMetricsSaga() {
    yield takeLatest(
        GET_INDIVIDUAL_AUTO_CONSUMPTION_BY_PRODUCERS_METRICS_DATA,
        getMetricByParticipantsMetrics
    );
}
export function* getAutoConsumptionByConsumersMetricsSaga() {
    yield takeLatest(
        GET_AUTO_CONSUMPTION_BY_CONSUMERS_METRICS_DATA,
        getMetricByParticipantsMetrics
    );
}
export function* getConsumptionByConsumersMetricsSaga() {
    yield takeLatest(
        GET_CONSUMPTION_BY_CONSUMERS_METRICS_DATA,
        getMetricByParticipantsMetrics
    );
}
export function* getSupplierConsumptionByProsumersMetricsSaga() {
    yield takeLatest(
        GET_SUPPLIER_CONSUMPTION_BY_PROSUMERS_METRICS_DATA,
        getMetricByParticipantsMetrics
    );
}
export function* getConsumerCDCSaga() {
    yield takeLatest(GET_CONSUMER_CDC_DATA, getConsumerCDC);
}

export function* getProducerCDCSaga() {
    yield takeLatest(GET_PRODUCER_CDC_DATA, getProducerCDC);
}

export function* getConsumerMetricsSaga() {
    yield takeLatest(GET_PARTICIPANT_METRICS_DATA, getParticipantCO2Metrics);
}

export function* getDistributionSaga() {
    yield takeLatest(GET_DISTRIBUTION_DATA, getDistribution);
}

const consolidateRanges = (dates) => {
    let consolidatedRange =
        dates.length > 0
            ? dates.reduce((acc, curr) => ({
                  from: acc.from < curr.from ? acc.from : curr.from,
                  to: acc.to > curr.to ? acc.to : curr.to,
              }))
            : null;
    const range = {
        from: consolidatedRange && moment(consolidatedRange.from),
        to:
            consolidatedRange &&
            // subtract one hour to handle sibelga tz (gmt+1)
            moment(consolidatedRange.to).subtract(1, 'hour'),
    };

    return range;
};

const measurementSagas = [
    getDateRangeSaga,
    getCDCSaga,
    getMetricsSaga,
    getConsumerCDCSaga,
    getProducerCDCSaga,
    getConsumerMetricsSaga,
    getDistributionSaga,
    getConsumptionMetricsSaga,
    getProductionMetricsSaga,
    getProductionByProducersMetricsSaga,
    getSurplusByProducersMetricsSaga,
    getAutoConsumptionByProducersMetricsSaga,
    getAutoConsumptionByConsumersMetricsSaga,
    getConsumptionByConsumersMetricsSaga,
    getConsumerConsumptionMetricsSaga,
    getProducerProductionMetricsSaga,
    getProsumerConsumptionMetricsSaga,
    getIndividualAutoConsumptionByProducersMetricsSaga,
    getSupplierConsumptionByProsumersMetricsSaga,
];

export default measurementSagas;
