import {
    INIT_BILLING_NEW,
    SET_BILLABLE_ENTITIES,
    SET_BILLING_ENTITIES,
    SET_INVOICES,
    SET_BILLING_PLANS,
    SET_BILLING_SUBSCRIPTIONS,
    SET_LAST_INVOICE_DATE,
    SET_BILLING_ENTITY_LOGO,
} from './constants';

const initialState = {
    participants: [],
    excess: [],
    downloadLoading: false,
    billingEntityLogo: '',
};

function billingNewReducer(state = initialState, action) {
    switch (action.type) {
        case INIT_BILLING_NEW: {
            return initialState;
        }
        case SET_BILLING_ENTITIES: {
            return { ...state, billingEntities: [...action.billingEntities] };
        }
        case SET_BILLABLE_ENTITIES: {
            return { ...state, billableEntities: [...action.billableEntities] };
        }
        case SET_INVOICES: {
            return { ...state, invoices: [...action.invoices] };
        }
        case SET_LAST_INVOICE_DATE: {
            return { ...state, lastInvoiceDate: action.lastInvoiceDate };
        }
        case SET_BILLING_PLANS: {
            return { ...state, billingPlans: [...action.billingPlans] };
        }
        case SET_BILLING_SUBSCRIPTIONS: {
            return {
                ...state,
                billingSubscriptions: [...action.billingSubscriptions],
            };
        }
        case SET_BILLING_ENTITY_LOGO: {
            const { billingEntityLogo } = action;
            return {
                ...state,
                billingEntityLogo: billingEntityLogo,
            };
        }
        default:
            return state;
    }
}

export default billingNewReducer;
