import { UPLOAD_SIBELGA_LOAD_CURVES } from '../../redux/reducers/constants';
import { call, getContext, put, takeLatest } from 'redux-saga/effects';
import {
    setError,
    setNotif,
    setSibelgaLoadCurvesUploadLoader,
} from '../../redux/actions';
import i18n from 'i18next';
import moment from 'moment';
import { MaxRetries } from '../../../adapters/secondary/gateways/upload/UploadGateway';
import { errorHandler } from '../common/errorHandler';

function* uploadSibelgaLoadCurves(action) {
    const { operationId, files } = action;
    yield put(setSibelgaLoadCurvesUploadLoader(true, operationId));

    const uploadGateway = yield getContext('uploadGateway');
    try {
        yield put(
            setNotif({
                message: i18n.t('enoapp.homePage.uploadFiles.uploadAlert'),
                variant: 'warning',
            })
        );
        yield call(uploadGateway.uploadSibelgaLoadCurves, operationId, files);
        yield put(
            setNotif({
                message: i18n.t('enoapp.homePage.uploadFiles.sent'),
            })
        );
    } catch (error) {
        yield errorHandler(error, false);

        const errorsMessages = [];
        if (error instanceof MaxRetries) {
            errorsMessages.push(i18n.t('common.snack.sibelga.uploadTooLong'));
        } else if (error.response?.data?.name === 'InvalidFilesNumber') {
            errorsMessages.push(
                i18n.t('common.snack.sibelga.invalidFilesNumber')
            );
        } else if (error.response?.data?.name === 'UploadInProgress') {
            errorsMessages.push(
                i18n.t('common.snack.sibelga.uploadInProgress')
            );
        } else if (error.response?.data?.name === 'UnknownFile') {
            const fileName = error.response.data.fileName;
            errorsMessages.push(
                i18n.t('common.snack.sibelga.unknownFile', {
                    fileName,
                })
            );
        } else if (error.response?.data?.name?.startsWith('IncompleteData')) {
            const { data } = error.response?.data;
            const { consumers, producers } = data;
            const MAX_DISPLAYED_DATES_NUMBER = 4;
            Object.entries(consumers).forEach(([ean, dates]) => {
                errorsMessages.push(
                    `${i18n.t('common.snack.sibelga.incompleteConsumerData', {
                        ean,
                        dates: dates
                            .slice(0, MAX_DISPLAYED_DATES_NUMBER)
                            .map((date) =>
                                moment
                                    .utc(date._date)
                                    //GMT+1
                                    .add(1, 'hours')
                                    .format('DD/MM/YYYY HH:mm')
                            )

                            .join(' , '),
                    })}${
                        dates.length > MAX_DISPLAYED_DATES_NUMBER ? ' ...' : ''
                    }`
                );
            });
            Object.entries(producers).forEach(([ean, dates]) => {
                errorsMessages.push(
                    `${i18n.t('common.snack.sibelga.incompleteProducerData', {
                        ean,
                        dates: dates
                            .slice(0, MAX_DISPLAYED_DATES_NUMBER)
                            .map((date) =>
                                moment
                                    .utc(date._date, '')
                                    .add(1, 'hours')
                                    .format('DD/MM/YYYY HH:mm')
                            )
                            .join(' , '),
                    })}${
                        dates.length > MAX_DISPLAYED_DATES_NUMBER ? ' ...' : ''
                    }`
                );
            });
        } else if (error.response?.data?.name === 'MissingColumns') {
            const columns = error.response?.data.columns.join(', ');
            const fileName = error.response?.data.fileName;
            errorsMessages.push(
                i18n.t('common.snack.sibelga.missingColumns', {
                    columns,
                    fileName,
                })
            );
        } else if (error.response?.data?.name === 'InconsistentPerimeter') {
            const { data } = error.response?.data;
            const { unexpected, missing } = data;
            missing.consumers.forEach((ean) => {
                errorsMessages.push(
                    i18n.t('common.snack.sibelga.missingConsumerData', {
                        ean,
                    })
                );
            });

            missing.producers.forEach((ean) => {
                errorsMessages.push(
                    i18n.t('common.snack.sibelga.missingProducerData', {
                        ean,
                    })
                );
            });

            unexpected.consumers.forEach((ean) => {
                errorsMessages.push(
                    i18n.t('common.snack.sibelga.unexpectedConsumerData', {
                        ean,
                    })
                );
            });

            unexpected.producers.forEach((ean) => {
                errorsMessages.push(
                    i18n.t('common.snack.sibelga.unexpectedProducerData', {
                        ean,
                    })
                );
            });
        } else if (error.response?.data?.name === 'InvalidPeriod') {
            const { data } = error.response?.data;
            const { participants } = data;
            participants.forEach((ean) => {
                errorsMessages.push(
                    i18n.t('common.snack.sibelga.invalidDateRange', {
                        ean,
                    })
                );
            });
        } else {
            errorsMessages.push(error.message || error.response.message);
        }
        for (let i = 0; i < errorsMessages.length; i++) {
            yield put(
                setError({
                    status: error.response && error.response.status,
                    message: errorsMessages[i],
                    persist: true,
                })
            );
        }
        console.error(error);
    }
    yield put(setSibelgaLoadCurvesUploadLoader(false));
}

function* uploadSibelgaLoadCurvesSaga() {
    yield takeLatest(UPLOAD_SIBELGA_LOAD_CURVES, uploadSibelgaLoadCurves);
}

const uploadSagas = [uploadSibelgaLoadCurvesSaga];

export default uploadSagas;
