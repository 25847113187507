import { call, getContext, put, takeLatest } from 'redux-saga/effects';
import { setAppMap } from '../../redux/actions';
import { GET_APPMAP } from '../../redux/reducers/constants';
import { errorHandler } from '../common/errorHandler';

function* getAppMap(action) {
    try {
        const navigationGateway = yield getContext('navigationGateway');
        const appMap = yield call(navigationGateway.getAppMap);
        yield put(setAppMap(appMap));
    } catch (error) {
        yield errorHandler(error);
    }
}

export function* getAppMapSaga() {
    yield takeLatest(GET_APPMAP, getAppMap);
}

const navigationSagas = [getAppMapSaga];

export default navigationSagas;
