import {
    call,
    getContext,
    put,
    select,
    takeLatest,
    all,
} from 'redux-saga/effects';
import {
    getConfigs as getConfigsAction,
    setParticipantsBills,
    setParticipantsConfigs,
    getBills as getBillsAction,
    setDownloadLoading,
    setContracts,
} from '../../redux/actions';
import {
    CREATE_CONFIG,
    DOWNLOAD_BILL_DOCUMENTS,
    DOWNLOAD_BILLING_STATUS,
    EDIT_CONFIG,
    CREATE_CONTRACT,
    GET_BILLS,
    GET_CONFIGS,
    REMOVE_CONFIG,
    UPDATE_BILLS,
} from '../../redux/reducers/constants';
import { configTypes } from '../../../../app-config';
import { selectSelectedOperation } from '../../redux/selectors/operationSelectors';
import { selectUser } from '../../redux/selectors/authenticationSelectors';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { saveAs } from 'file-saver';
import {
    makeSelectConfig,
    selectMapConfigIdToContracts,
} from '../../redux/selectors/billingSelectors';
import { errorHandler } from '../common/errorHandler';

function* getConfigs(action) {
    try {
        const { configType } = action;
        const billingGateway = yield getContext('billingGateway');
        const user = yield select(selectUser);
        const operation = yield select(selectSelectedOperation);
        let configs = [];
        let contracts = [];
        if (user && user.role === 'participant') {
            configs = yield call(
                billingGateway.getProducerConfigs,
                operation.id,
                operation.activeProducerId
            );
            contracts = yield call(
                billingGateway.getProducerContracts,
                operation.id,
                operation.activeProducerId
            );
        } else {
            configs = yield call(
                billingGateway.getConfigs,
                operation.id,
                configType
            );
            contracts = yield call(billingGateway.getContracts, operation.id);
        }
        yield put(setParticipantsConfigs(configs));
        yield put(setContracts(contracts));
    } catch (error) {
        yield errorHandler(error);
    }
}

function* createConfig(action) {
    try {
        const { config, configType } = action;

        const operation = yield select(selectSelectedOperation);
        const billingGateway = yield getContext('billingGateway');
        const { id: configId } = yield call(
            billingGateway.createConfig,
            config,
            operation.id
        );
        const contractsCalls = [];
        config.producers.forEach((producerId) => {
            config.consumers.forEach((consumerId) => {
                const contract = {
                    producerId,
                    consumerId,
                    configId,
                    startDate: config.startDate,
                };
                contractsCalls.push(
                    call(billingGateway.createContract, contract, operation.id)
                );
            });
        });
        yield all(contractsCalls);
        yield put(getConfigsAction(configType));
    } catch (error) {
        yield errorHandler(error);
    }
}

function* deleteConfig(action) {
    try {
        const { configId, configType } = action;
        const billingGateway = yield getContext('billingGateway');
        const operation = yield select(selectSelectedOperation);
        yield call(billingGateway.deleteConfig, operation.id, configId);
        yield put(getConfigsAction(configType));
    } catch (error) {
        yield errorHandler(error);
    }
}

function* editConfig(action) {
    try {
        const { config, configType, isNewConfig } = action;
        const billingGateway = yield getContext('billingGateway');
        const operation = yield select(selectSelectedOperation);
        const allContracts = yield select(selectMapConfigIdToContracts);
        const contracts = allContracts[config.id];
        if (isNewConfig) {
            yield call(createConfig, { config, configType });
            return;
        }
        const oldConfig = yield select(makeSelectConfig(config.id));

        const startDateChanged = config.startDate !== oldConfig.startDate;
        const newContracts = [];
        config.producers.forEach((producerId) => {
            config.consumers.forEach((consumerId) => {
                if (
                    startDateChanged ||
                    !contracts.some(
                        (contract) =>
                            consumerId === contract.consumerId &&
                            producerId === contract.producerId
                    )
                ) {
                    newContracts.push({
                        producerId,
                        consumerId,
                        configId: config.id,
                        startDate: config.startDate,
                    });
                }
            });
        });
        const contractsToDeleteIds = [];
        contracts.forEach((contract) => {
            if (
                startDateChanged ||
                config.producers.indexOf(contract.producerId) < 0 ||
                config.consumers.indexOf(contract.consumerId) < 0
            ) {
                contractsToDeleteIds.push(contract.id);
            }
        });
        if (
            config.sellingPrice !== oldConfig.sellingPrice ||
            config.anniversary !== oldConfig.anniversary ||
            config.period !== oldConfig.period ||
            config.paymentDeadline !== oldConfig.paymentDeadline ||
            config.paymentMethod !== oldConfig.paymentMethod ||
            config.subscriptionPrice !== oldConfig.subscriptionPrice
        ) {
            yield call(
                billingGateway.editConfig,
                config,
                operation.id,
                configType
            );
        }
        // Execute the delete calls sequentially to avoid 503 errors when there are multiple calls
        for (let i = 0; i < contractsToDeleteIds.length; i++) {
            yield call(
                billingGateway.deleteContract,
                operation.id,
                contractsToDeleteIds[i]
            );
        }

        // Execute the create calls sequentially to avoid 503 errors when there are multiple calls
        for (let i = 0; i < newContracts.length; i++) {
            yield call(
                billingGateway.createContract,
                newContracts[i],
                operation.id
            );
        }

        yield put(getConfigsAction(configType));
    } catch (error) {
        yield errorHandler(error);
    }
}

function* createContract(action) {
    try {
        const { configId, producerId, consumerId, startDate } = action;
        const billingGateway = yield getContext('billingGateway');
        const operation = yield select(selectSelectedOperation);
        const contract = {
            producerId,
            consumerId,
            configId,
            startDate,
        };
        yield call(billingGateway.createContract, contract, operation.id);
        yield put(getConfigsAction(configTypes.PARTICIPANT));
    } catch (error) {
        yield errorHandler(error);
    }
}

function* downloadBillDocuments(action) {
    try {
        const { bills } = action;
        const billingGateway = yield getContext('billingGateway');

        if (bills.length === 1) {
            const url = yield call(
                billingGateway.getBillDocumentPresignedUrl,
                bills[0]
            );
            window.location.href = url.data.url;
        } else {
            yield put(setDownloadLoading(true));
            const urlsResponses = yield call(
                billingGateway.getMultipleBillDocumentsPresignedUrlObjects,
                bills
            );
            const urlObjects = urlsResponses.map((item) => {
                return {
                    title: item.title,
                    url: item.content.data.url,
                };
            });
            const period = `f-${bills[0].billingPeriodEnd}`;
            const producer = bills[0].producer;
            const zipFilename = `${period}-${producer.billingDetail.name}.zip`;
            yield downloadZipFromUrlObjects(urlObjects, zipFilename);
        }
    } catch (error) {
        yield errorHandler(error);
    }
    yield put(setDownloadLoading(false));
}

const downloadZipFromUrlObjects = async (urlObjects, zipFilename) => {
    const zip = new JSZip();
    await Promise.all(
        urlObjects.map(async (urlObject) => {
            const data = await JSZipUtils.getBinaryContent(urlObject.url);
            zip.file(urlObject.title, data, { binary: true });
        })
    );
    const content = await zip.generateAsync({ type: 'blob' });
    saveAs(content, zipFilename);
};

function* getBills(action) {
    try {
        const { configType } = action;
        const billingGateway = yield getContext('billingGateway');
        const operation = yield select(selectSelectedOperation);
        const user = yield select(selectUser);
        let bills;
        if (user.role === 'participant') {
            bills = yield call(
                billingGateway.getParticipantBills,
                operation.activeProducerId,
                configType
            );
        } else {
            bills = yield call(
                billingGateway.getBills,
                operation.id,
                configType
            );
        }
        yield put(setParticipantsBills(bills));
    } catch (error) {
        yield errorHandler(error);
    }
}

function* updateBills(action) {
    try {
        const { bills } = action;
        const billingGateway = yield getContext('billingGateway');
        const operation = yield select(selectSelectedOperation);
        yield call(billingGateway.updateBills, operation.id, bills);
        yield put(
            getBillsAction(bills[0].consumer ? configTypes.PARTICIPANT : null)
        );
    } catch (error) {
        yield errorHandler(error);
    }
}

function* downloadBillingStatus(action) {
    try {
        const { producer, billingPeriodEnd } = action;
        const billingGateway = yield getContext('billingGateway');
        yield call(
            billingGateway.downloadBillingStatus,
            producer.id,
            billingPeriodEnd
        );
    } catch (error) {
        yield errorHandler(error);
    }
}

export function* getConfigsSaga() {
    yield takeLatest(GET_CONFIGS, getConfigs);
}

export function* createConfigSaga() {
    yield takeLatest(CREATE_CONFIG, createConfig);
}

export function* deleteConfigSaga() {
    yield takeLatest(REMOVE_CONFIG, deleteConfig);
}

export function* editConfigSaga() {
    yield takeLatest(EDIT_CONFIG, editConfig);
}

export function* addConfigParticipantsSaga() {
    yield takeLatest(CREATE_CONTRACT, createContract);
}

export function* downloadBillDocumentsSaga() {
    yield takeLatest(DOWNLOAD_BILL_DOCUMENTS, downloadBillDocuments);
}

export function* getBillsSaga() {
    yield takeLatest(GET_BILLS, getBills);
}

export function* updateBillsSaga() {
    yield takeLatest(UPDATE_BILLS, updateBills);
}

export function* downloadBillingStatusSaga() {
    yield takeLatest(DOWNLOAD_BILLING_STATUS, downloadBillingStatus);
}

const billingSagas = [
    getConfigsSaga,
    createConfigSaga,
    deleteConfigSaga,
    editConfigSaga,
    addConfigParticipantsSaga,
    downloadBillDocumentsSaga,
    getBillsSaga,
    updateBillsSaga,
    downloadBillingStatusSaga,
];

export default billingSagas;
